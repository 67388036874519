import {
    Button,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    TableRow,
    TextField,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
} from '@material-ui/core';
import { AddOutlined, EditOutlined } from '@material-ui/icons';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { NotificationManager } from 'react-notifications';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { getComparator, stableSort } from '../../component/table/function';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import './integration.css';
import EnhancedTableHead from '../../component/table/EnhancedTableHead';

const Integration = () => {
    const closeSidebar = useSelector((state) => state.reducer.closeSidebar);
    const history = useHistory();
    const [order, setOrder] = useState('desc');
    const [orderBy, setOrderBy] = useState('integration_name');
    const [integrationList, setIntegrationList] = useState([]);
    const [apiLoading, setApiLoading] = useState(true);
    const [rowsPerPage, setRowsPerPage] = useState(20);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [totalRecords, setTotalRecords] = useState(0);
    const [statusFilter, setStatusFilter] = useState('All'); // New state for dropdown filter

    const getIntegrations = async () => {
        setApiLoading(true);
        const body = {
            SearchText: searchTerm.trim(),
            PageSize: rowsPerPage,
            CurrentPage: page + 1,
            SortOrder: order,
            SortCol: orderBy,
        };

        try {
            const res = await http.post(`/api/v1/Integration/IntegrationMaster/Get`, body);
            setTotalRecords(res?.RecordCount ?? 0);
            setIntegrationList(res?.Value ?? []);
        } catch (error) {
            console.error(error);
            NotificationManager.error('Something went wrong', '', 3000);
        } finally {
            setApiLoading(false);
        }
    };

    useEffect(() => {
        getIntegrations();
    }, [searchTerm, rowsPerPage, page, order, orderBy]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value));
        setPage(0);
    };

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
        setPage(0);
    };

    const handleRequestSort = (e, column) => {
        if (orderBy === column) {
            setOrder(order === 'asc' ? 'desc' : 'asc');
        } else {
            setOrderBy(column);
            setOrder('desc');
        }
    };

    const handleFilterChange = (event) => {
        setStatusFilter(event.target.value);
        setPage(0);
    };

    // Filter the integration list based on the status filter
    const filteredIntegrations = integrationList.filter((row) => {
        if (statusFilter === 'All') return true;
        if (statusFilter === 'Active') return row.integration_status;
        if (statusFilter === 'Inactive') return !row.integration_status;
        return true;
    });

    const headCells = [
        { id: 'integration_name', label: 'Integrations', sortable: true },
        { id: 'integration_source_name', label: 'Source', sortable: true },
        { id: 'createdon', label: 'Last Updated', sortable: true },
        { id: 'integration_status', label: 'Status', sortable: true },
    ];

    return (
        <div className={`mainSection integrationComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`}>
            <div className="mainSectionHeading withAddnew">
                <span>Integrations</span>
                <span>
                    <Button
                        variant="contained"
                        onClick={() => {
                            history.push(appRoute.AddIntegration);
                        }}
                        endIcon={<AddOutlined />}
                    >
                        Add Integration
                    </Button>
                </span>
            </div>
            <div className="tableParent" >
                <div className="filterSection" style={{float:'right'}}>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                <FormControl variant="standard" style={{ width: '150px' }}>
                        <InputLabel>Status</InputLabel>
                        <Select value={statusFilter} onChange={handleFilterChange}>
                            <MenuItem value="All">All</MenuItem>
                            <MenuItem value="Active">Active</MenuItem>
                            <MenuItem value="Inactive">Inactive</MenuItem>
                        </Select>
                    </FormControl>
                    <TextField
                        label="Search"
                        variant="standard"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        style={{ width: '300px', marginRight: 16, marginLeft:15 }}
                    />
                  </div>
                  </div>
                  </div>
                </div>
                <TableContainer>
                    <Table stickyHeader>
                        <EnhancedTableHead
                            headCells={headCells}
                            order={order}
                            orderBy={orderBy}
                            onRequestSort={handleRequestSort}
                            action={true}
                        />
                        <TableBody>
                            {filteredIntegrations.length ? (
                                stableSort(filteredIntegrations, getComparator(order, orderBy)).map((row, index) => {
                                    let count = index + 1 + page * rowsPerPage;
                                    let status = row.integration_status ? 'Active' : 'Inactive';
                                    let date = moment(row.createdon).format('DD-MM-YYYY H:mm A');

                                    return (
                                        <TableRow hover key={count} className={!row.status && 'notActive'}>
                                            <TableCell align="center">{count}</TableCell>
                                            <TableCell title={row.integration_name}>{row.integration_name}</TableCell>
                                            <TableCell title={row.source_endpoint_name}>
                                                {row.source_endpoint_name ?? ''}
                                            </TableCell>
                                            <TableCell title={row.createdon}>{date}</TableCell>
                                            <TableCell title={status} className="status">
                                                <span
                                                    className={
                                                        row.integration_status ? `integActive` : `integDeactive`
                                                    }
                                                    title={status}
                                                >
                                                    {status}
                                                </span>
                                            </TableCell>
                                            <TableCell title="View Details">
                                                <IconButton
                                                    color="primary"
                                                    aria-label="Edit Integration"
                                                    size="small"
                                                    onClick={() => {
                                                        history.push(`/integrations/edit/${row.integration_id}`);
                                                    }}
                                                >
                                                    <EditOutlined />
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    );
                                })
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={6} align="center">
                                        No integrations found
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 20, 50, 100]}
                    component="div"
                    count={totalRecords}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </div>
        </div>
    );
};

export default Integration;
