import { Button, Tooltip } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import appRoute from '../../Route/appRoutes';
import http from '../../api';
import { showErrorMessage, showSuccessMessage, validateCommaSeparatedEmailList } from '../../component/table/function';
import './integration.css';
import { Info } from '@material-ui/icons';

function IntegrationAdd() {
    const closeSidebar = useSelector(state => state.reducer.closeSidebar)
    const history = useHistory();
    const { id } = useParams();
    const isEdit = id ? true : false;
    const [loading, setLoading] = useState(true);
    const [patternMatching, setPatternMatching] = useState(false);
    const [notificationSuccess, setNotificationSuccess] = useState(false);
    const [notificationFailure, setNotificationFailure] = useState(false);
    // const [timerStatus, setTimerStatus] = useState(false);
    const [integrationStatus, setIntegrationStatus] = useState(true);
    const [endpoints, setEndpoints] = useState([]);
    const [endpointInfo, setEndpointInfo] = useState({});
    const [integrationTypes, setIntegrationTypes] = useState([]);
    const [selectedDestinations, setSelectedDestinations] = useState([])
    const [formData, setFormData] = useState({
        integration_name: '',
        integration_type: '',
        connection_url: '',
        source_endpoint: '',
        filename_pattern: '',
        content_pattern_1: '',
        content_pattern_2: '',
        content_pattern_3: '',
        runat_every: '',
        runat_time: '',
        notification_success_emails: '',
        notification_failure_emails: '',
        destinations:[],
    })
    const runatSlots = Array.apply(null, {length: 60}).map(Number.call, Number);

    const getIntegration = () => {
        const body = {
            "id": id,
        }

        http.post(`/api/v1/Integration/IntegrationMaster/Get`, body)
            .then((res) => {
                const integration = res.Value.length ? res.Value[0] : []
                if ( integration ) {
                    setFormData({
                        integration_name: integration.integration_name,
                        integration_type: integration.integration_type_id,
                        connection_url: integration.conversion_url,
                        source_endpoint: integration.source_endpoint_id,
                        filename_pattern: integration.filename_pattern,
                        content_pattern_1: integration.content_pattern_01,
                        content_pattern_2: integration.content_pattern_02,
                        content_pattern_3: integration.content_pattern_03,
                        runat_every: integration.runafterevery,
                        runat_time: integration.runafter,
                        notification_success_emails: integration.notify_forsuccess_at,
                        notification_failure_emails: integration.notify_forfaliure_at,
                    })
                    setNotificationSuccess(integration.notify_onsuccess)
                    setNotificationFailure(integration.notify_onfailure)
                    setPatternMatching(integration.use_pattern_matching)
                    // setTimerStatus(integration.timer_status)
                    setIntegrationStatus(integration.integration_status)
                    const destinations = integration.destinations.split(',').map((e) => parseInt(e));
                    setSelectedDestinations(destinations)
                }
            }).catch((error) => {
                console.log(error);
                showErrorMessage('Something went wrong');
            })
    }

    useEffect(() => {
        const getIntegrationTypes = () => {
            http.post(`/api/v1/Integration/IntegrationType/Get`)
                .then(res => {
                    setIntegrationTypes(res?.Value ?? [])
                })
                .catch(err => {
                    console.log(err);
                    showErrorMessage('Failed to load Data');
                })
        }

        const getEndpoints = () => {
            const body = {
                // "SearchText": "",
                // "PageSize": 999999,
                // "CurrentPage": 1,
                // "EndpointId": null,
                // "ActivationStatus": null,
                // "Organization": null,
                "SortOrder": "desc",
                "SortCol": "endpoint_name"
            }

            http.post(`api/v1/Endpoint/EndpointMaster/Get`, body)
                .then(res => {
                    setEndpoints(res?.Value ?? [])
                })
                .catch(err => {
                    console.log(err);
                    showErrorMessage('Failed to load Data');
                })
        }

        getIntegrationTypes();
        getEndpoints();
        setLoading(false);
    }, [])

    useEffect(() => {
        if ( isEdit ) {
            getIntegration()
        }
    }, []);

  
    


    const handleDestinationChange = (e) => {
        const { value, checked } = e.target;
        if (checked) {
          setSelectedDestinations((prevDestinations) => [...prevDestinations, parseInt(value)]);
        } else {
          setSelectedDestinations((prevDestinations) =>
            prevDestinations.filter((destination) => destination !== parseInt(value))
          );
        }
    };

    useEffect(() => {
        if (isEdit && formData.destinations) {
            // Check if destinations is already an array
            if (Array.isArray(formData.destinations)) {
                setSelectedDestinations(formData.destinations.map(Number));
            } else if (typeof formData.destinations === 'string') {
                // If it's a string, split it into an array
                setSelectedDestinations(formData.destinations.split(',').map(Number));
                console.log('Heelo', formData.destinations);
            }
        }
    }, [isEdit, formData.destinations]);
    

    // useEffect(() => {
    //     if (isEdit && formData.destinations) {
    //       setSelectedDestinations(formData.destinations.split(',').map(Number)); // Assuming `formData.destinations` is a comma-separated string
    //     }
    // }, [isEdit, formData.destinations]);

    useEffect(() => {
        console.log('Selected Destinations:', selectedDestinations);
    }, [selectedDestinations]);
    

    const handleFormFieldsChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });

        if ( e.target.name === 'source_endpoint' && e.target.value ) {
            setEndpointInfo(endpoints.filter(endpoint => endpoint.endpoint_id === parseInt(e.target.value))[0])
        }
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!formData.integration_name.length || !formData.integration_type || !formData.source_endpoint || !selectedDestinations.length) {
            showErrorMessage('Please fill all the fields.')
            return false;
        }

        if (formData.integration_type == 2 && !formData.connection_url.length) {
            showErrorMessage('Connection URL is required field.')
            return false;
        }

        // if (patternMatching && (!formData.filename_pattern.length && !formData.content_pattern_1.length && !formData.content_pattern_2.length && !formData.content_pattern_3.length)) {
        if (patternMatching && (!formData.filename_pattern.length && !formData.content_pattern_1.length && !formData.content_pattern_2.length) ) {
            showErrorMessage('Please fill all the fields.')
            return false;
        }

        if (notificationSuccess) {
            if (!formData.notification_success_emails.length) {
                showErrorMessage('Please fill all the fields.')
                return false;
            } else if (!validateCommaSeparatedEmailList(formData.notification_success_emails)) {
                showErrorMessage('Please enter valid comma separated email addresses.')
                return false;
            }
        }

        if (notificationFailure) {
            if (!formData.notification_failure_emails.length) {
                showErrorMessage('Please fill all the fields.')
                return false;
            } else if (!validateCommaSeparatedEmailList(formData.notification_failure_emails)) {
                showErrorMessage('Please enter valid comma separated email addresses.')
                return false;
            }
        }

        // check integration type id
        const body = {
            "oper": isEdit ? "U" : "I",
            "isactive": true,
            "createdby": parseInt(JSON.parse(localStorage.userdata).id),
            "conversion_url": formData.connection_url,
            "integration_name": formData.integration_name,
            "integration_type_id": formData.integration_type,
            "source_endpoint_id": formData.source_endpoint,
            "use_pattern_matching": patternMatching,
            "filename_pattern": formData.filename_pattern,
            "content_pattern_01": formData.content_pattern_1,
            "content_pattern_02": formData.content_pattern_2,
            "content_pattern_03": '',
            "destinations": selectedDestinations.join(','),

          
            "notify_onsuccess": notificationSuccess,
            "notify_onfailure": notificationFailure,
            "notify_forfaliure_at": formData.notification_failure_emails.split(',').map(email => email.trim()).join(','),
            "notify_forsuccess_at": formData.notification_success_emails.split(',').map(email => email.trim()).join(','),
            // "runafter": formData.runat_time,
            "runafter": 'm',
            // "runafterevery": formData.runat_every,
            "runafterevery": 1,
            "timer_status": 1,
            "integration_status": integrationStatus
        }

        if ( isEdit ) {
            body.integration_id = parseInt(id);
            
        }
        console.log("Destinations: ", selectedDestinations.join(','));
            console.log("Request Body: ", body);

        http.post(`/api/v1/Integration/IntegrationMaster/POST`, body)
            .then((res) => {
                console.log("API Response: ", res);
                if (res.Success) {
                    showSuccessMessage(res.ResultMessage);
                    setTimeout(() => {
                        history.push(appRoute.INTEGRATION)
                    }, 3500);
                    e.target.reset()
                } else {
                    showErrorMessage(res.ResultMessage);
                }
            }).catch((error) => {
                console.log(error)
                showErrorMessage('Something went wrong');
            })
    }

    if (loading) {
        return null;
    }

    return (
        <div className={`mainSection IntegDetailsComponent ${closeSidebar ? 'maxMainSection' : 'minMainSection'}`} >
            <div className='mainSectionHeading'>{isEdit ? 'Edit' : 'Add New'} Integration</div>
            <div className='sorceDataDiv'>
                <form onSubmit={handleSubmit}>
                    <div className="row mb-3">
                        <label htmlFor="integration_name" className="col-3 col-form-label">Integration Name:</label>
                        <div className="col-9">
                            <input type='text' value={formData.integration_name} id="integration_name" name="integration_name" className='form-control' onChange={handleFormFieldsChange} />
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label htmlFor="integration_type" className="col-3 col-form-label">Integration Type:</label>
                        <div className="col-9">
                            <select name="integration_type" id="integration_type" className="form-select" onChange={handleFormFieldsChange} value={formData.integration_type}>
                                <option>-- Select --</option>
                                {integrationTypes.map((integrationType, index) => (
                                    <option value={integrationType.integration_type_id} key={index}>{integrationType.integration_type_name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    {
                        formData.integration_type == 2 ?
                            (<div className="row mb-3">
                                <label htmlFor="connection_url" className="col-3 col-form-label">Connection URL:</label>
                                <div className="col-9">
                                    <input type='url' value={formData.connection_url} id="connection_url" name="connection_url" className='form-control' onChange={handleFormFieldsChange} />
                                </div>
                            </div>) : ''
                    }
                    <div className="row mb-3">
                        <label htmlFor="source_endpoint" className="col-3 col-form-label">Source Endpoint:</label>
                        <div className="col-9">
                            <select name="source_endpoint" id="source_endpoint" className="form-select" onChange={handleFormFieldsChange} value={formData.source_endpoint}>
                                <option>-- Select --</option>
                                {endpoints.map((endpoint, index) => (
                                    <option value={endpoint.endpoint_id} key={index}>{endpoint.endpoint_name}</option>
                                ))}
                            </select>
                            {(() => {
                                    if ( endpointInfo && endpointInfo.endpoint_type_name ) {
                                        const endpointName = endpointInfo.endpoint_type_name.toLowerCase();
                                        if ( ['sftp', 'ftp', 'email'].indexOf(endpointName) !== -1 ) {
                                            return (
                                                <div className="source-endpoint-info">
                                                    <div className='label'>Type:</div> <div className='value'>{endpointInfo.endpoint_type_name},</div>
                                                    <div className='label'>Hostname:</div> <div className='value'>{endpointInfo.endpoint_hostname},</div>
                                                    <div className='label'>Port:</div> <div className='value'>{endpointInfo.endpoint_port},</div>
                                                    <div className='label'>Relative Path:</div> <div className='value'>{endpointInfo.endpoint_relativepath}</div>
                                                </div>
                                            )
                                        } else if ('folderlocation' === endpointName) {
                                            return (
                                                <div className="source-endpoint-info">
                                                    <div className='label'>Type:</div> <div className='value'>{endpointInfo.endpoint_type_name},</div>
                                                    <div className='label'>Absolute Path:</div> <div className='value'>{endpointInfo.endpoint_absolutepath}</div>
                                                </div>
                                            )
                                        } else if ('api' === endpointName) {
                                            return (
                                                <div className="source-endpoint-info">
                                                    <div className='label'>Type:</div> <div className='value'>{endpointInfo.endpoint_type_name},</div>
                                                    <div className='label'>URL:</div> <div className='value'>{endpointInfo.endpoint_url}</div>
                                                </div>
                                            )
                                        } else {
                                            return (
                                                <div className="source-endpoint-info">
                                                    <div className='label'>Type:</div> <div className='value'>{endpointInfo.endpoint_type_name}</div>
                                                </div>
                                            )
                                        }
                                    }
                            })()}
                        </div>  
                    </div>
                    <div className="row mb-3">
                        <label className="form-check-label col-3" htmlFor="pattern_matching">Pattern Matching:</label>
                        <div className="col-9">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="pattern_matching_yes_no" onChange={(e) => setPatternMatching(e.target.checked)} checked={patternMatching} /> 
                                <label className="form-check-label" htmlFor="pattern_matching_yes_no">{patternMatching ? 'Yes' : 'No'}</label>
                            </div>
                        </div>
                    </div>
                    {
                        patternMatching ?
                            (<>
                                <div className="row mb-3">
                                    <label htmlFor="filename_pattern" className="col-3 col-form-label">Filename Pattern:</label>
                                    <div className="col-9">
                                        <input type='text' value={formData.filename_pattern} id="filename_pattern" name="filename_pattern" className='form-control' onChange={handleFormFieldsChange} />
                                    </div>
                                </div>
                                {[1, 2].map((num, index) => (
                                    <div className="row mb-3" key={index}>
                                        <label htmlFor={`content_pattern_${num}`} className="col-3 col-form-label">
                                            Content Pattern {num}:
                                            <Tooltip title={`Content Pattern ${num}`} placement='right'>
                                                <Info size="small" />
                                            </Tooltip>
                                        </label>
                                        <div className="col-9">
                                            <input type='text' className='form-control' id={`content_pattern_${num}`} name={`content_pattern_${num}`} value={formData[`content_pattern_${num}`]} onChange={handleFormFieldsChange} />
                                        </div>
                                    </div>
                                ))}
                            </>) : ''
                    }
                    <div className="row mb-3">
                        <label htmlFor="destinations" className="col-3 col-form-label">Destinations:</label>
                        <div className='col-8'>
                            {endpoints.map((endpoint, index) => (
                                <div className="form-check" key={index}>
                                   <input
    className="form-check-input"
    type="checkbox"
    id={`destination_${index}`}
    value={endpoint.endpoint_id}
    onChange={handleDestinationChange}
    checked={selectedDestinations.includes(endpoint.endpoint_id)}  // Correctly check the selected state
/>
                                    <label className="form-check-label destinations-label" htmlFor={`destination_${index}`}>
                                        <span>{endpoint.endpoint_name}</span>
                                        {(() => {
                                            const endpointName = endpoint.endpoint_type_name.toLowerCase();
                                            const show =  selectedDestinations.some((d) => d === endpoint.endpoint_id) ? 'show' : ''
                                            if ( ['sftp', 'ftp', 'email'].indexOf(endpointName) !== -1 ) {
                                                return (
                                                    <div className={`source-endpoint-info ${show}`}>
                                                        <div className='label'>Type:</div> <div className='value'>{endpoint.endpoint_type_name},</div>
                                                        <div className='label'>Hostname:</div> <div className='value'>{endpoint.endpoint_hostname},</div>
                                                        <div className='label'>Port:</div> <div className='value'>{endpoint.endpoint_port},</div>
                                                        <div className='label'>Relative Path:</div> <div className='value'>{endpoint.endpoint_relativepath}</div>
                                                    </div>
                                                )
                                            } else if ('folderlocation' === endpointName) {
                                                return (
                                                    <div className={`source-endpoint-info ${show}`}>
                                                        <div className='label'>Type:</div> <div className='value'>{endpoint.endpoint_type_name},</div>
                                                        <div className='label'>Absolute Path:</div> <div className='value'>{endpoint.endpoint_absolutepath}</div>
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div className={`source-endpoint-info ${show}`}>
                                                        <div className='label'>Type:</div> <div className='value'>{endpoint.endpoint_type_name}</div>
                                                    </div>
                                                )
                                            }
                                        })()}
                                    </label>
                                </div>
                            ))}
                        </div>
                    </div>
                    {}
                    <div className="row mb-3">
                        <label className="form-check-label col-3" htmlFor="notification_success">Notification (On success):</label>
                        <div className="col-9">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="notification_success" onChange={(e) => setNotificationSuccess(e.target.checked)} checked={notificationSuccess} />
                                </div>
                                {
                                    notificationSuccess ? (
                                        <input className="form-control" type="text" name="notification_success_emails" value={formData.notification_success_emails} id="notification_success_emails" placeholder="Recepient email ids (Comma seperated)" onChange={handleFormFieldsChange} />
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="form-check-label col-3" htmlFor="notification_failure">Notification (On failure):</label>
                        <div className="col-9">
                            <div className="d-flex gap-2 align-items-center">
                                <div className="form-check form-switch">
                                    <input className="form-check-input" type="checkbox" id="notification_failure" onChange={(e) => setNotificationFailure(e.target.checked)} checked={notificationFailure} />
                                </div>
                                {
                                    notificationFailure ? (
                                        <input className="form-control" type="text" name="notification_failure_emails" value={formData.notification_failure_emails} id="notification_failure_emails" placeholder="Recepient email ids (Comma seperated)" onChange={handleFormFieldsChange} />
                                    ) : ''
                                }
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3">
                        <label className="form-check-label col-3" htmlFor="integration_status">Status:</label>
                        <div className="col-9">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="integration_status_yes_no" onChange={(e) => setIntegrationStatus(e.target.checked)} checked={integrationStatus} />
                                <label className="form-check-label" htmlFor="integration_status_yes_no">{integrationStatus ? 'Active' : 'Inactive'}</label>
                            </div>
                        </div>
                    </div>
                    <Button type="submit" variant='contained' className='editNsave'>Save</Button>
                </form>
            </div>
        </div >
    )
}

export default IntegrationAdd
